import React,{useState,useEffect} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import CustomeDrawer from '../CustomeDrawer';
import { useNavigate } from 'react-router-dom';

// const pages = ['Pricing', 'FAQs','Blog'];

const pages = [
  {id:1,name:'Pricing',to:"/pricing"},
  {id:2,name:'FAQs',to:"/faqs"},
  {id:3,name:'Support',to:"/support"},
];

const  Header = () => {
  const themes = useTheme();
  const {appBar} = themes
  const navigate = useNavigate()
  const [openDrawer, setOpenDrawer] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      const header = document.getElementById('header');
      const sticky = header.offsetTop;

      if (window.pageYOffset > sticky) {
        header.classList.add('wabsticky');
      } else {
        header.classList.remove('wabsticky');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
  const handleOpenNavMenu = (event) => {
      setOpenDrawer(true);
  };

  const handleCloseNavMenu = (state) => {
    setOpenDrawer(state);
  };

  const routeHandler = (url) =>{
    if (url!=''){
      navigate(url)
    }
  } 

  const goToexe = () =>{
    window.open('https://chromewebstore.google.com/detail/wablasters-bulk-wa-messag/hmfggmookomommnebacphkkignbollcm', '_blank');
  }

  return (
    <>
      <AppBar position="sticky" id="header" sx={appBar.header} >
        <Container>
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="div"
              onClick={()=>{routeHandler('/')}}
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'nunito, sans-serif',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
                fontSize:"30px",
                cursor: 'pointer',
              }}
            >
              <strong><span className='Wac'>WA</span><span className='blc'>Blasters</span></strong>
            </Typography>
            
            {/* Mobile Menu */}
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
                style={{
                  color:"#00a884",
                  fontSize:"28px",
                }}  
              >
                <MenuIcon />
              </IconButton>
            </Box>

            <Typography
              variant="h5"
              noWrap
              component="a"
              href="#app-bar-with-responsive-menu"
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
                fontSize:"30px"
              }}
            >
              <strong><span className='Wac'>WA</span><span className='blc'>Blasters</span></strong>
            </Typography>

            <Box sx={appBar.menuBox}>
              {pages.map((page) => (
                <Typography
                  className='wablastersMenu'
                  key={page}
                  sx={appBar.menu}
                  onClick={()=>{routeHandler(page.to)}}
                >
                  {page.name}
                </Typography>
              ))}
            </Box>

            <Box sx={appBar.btnBox}>
              <Button sx={appBar.btn} onClick={()=>goToexe()} style={{marginRight:"22px"}}>Try for Free</Button>
              <Button sx={appBar.btn} onClick={()=>{routeHandler('/pricing')}}>Get Premium</Button>
            </Box>

          </Toolbar>
        </Container>
      </AppBar>
      <CustomeDrawer open={openDrawer} closeHandler={(state)=>{handleCloseNavMenu(state)}}/>
    </>
  );
}
export default Header;
